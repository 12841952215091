"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const TagCount_vue_1 = require("@/client/components/TagCount.vue");
const Tag_1 = require("@/common/cards/Tag");
const SpecialTags_1 = require("@/client/cards/SpecialTags");
const PlayerTagDiscount_vue_1 = require("@/client/components/overview/PlayerTagDiscount.vue");
const PointsPerTag_vue_1 = require("@/client/components/overview/PointsPerTag.vue");
const PartyName_1 = require("@/common/turmoil/PartyName");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const vueRoot_1 = require("@/client/components/vueRoot");
const CardName_1 = require("@/common/cards/CardName");
const ORDER = [
    Tag_1.Tag.BUILDING,
    Tag_1.Tag.SPACE,
    Tag_1.Tag.SCIENCE,
    Tag_1.Tag.POWER,
    Tag_1.Tag.EARTH,
    Tag_1.Tag.JOVIAN,
    Tag_1.Tag.VENUS,
    Tag_1.Tag.PLANT,
    Tag_1.Tag.MICROBE,
    Tag_1.Tag.ANIMAL,
    Tag_1.Tag.CITY,
    Tag_1.Tag.MOON,
    Tag_1.Tag.MARS,
    'separator',
    Tag_1.Tag.EVENT,
    SpecialTags_1.SpecialTags.NONE,
    Tag_1.Tag.WILD,
    SpecialTags_1.SpecialTags.INFLUENCE,
    SpecialTags_1.SpecialTags.CITY_COUNT,
    SpecialTags_1.SpecialTags.COLONY_COUNT,
];
const isInGame = (tag, game) => {
    if (game.gameOptions.coloniesExtension === false && tag === SpecialTags_1.SpecialTags.COLONY_COUNT)
        return false;
    if (game.turmoil === undefined && tag === SpecialTags_1.SpecialTags.INFLUENCE)
        return false;
    if (game.gameOptions.venusNextExtension === false && tag === Tag_1.Tag.VENUS)
        return false;
    if (game.gameOptions.moonExpansion === false && tag === Tag_1.Tag.MOON)
        return false;
    if (game.gameOptions.pathfindersExpansion === false && tag === Tag_1.Tag.MARS)
        return false;
    return true;
};
const getTagCount = (tagName, player) => {
    var _a, _b;
    if (tagName === SpecialTags_1.SpecialTags.COLONY_COUNT) {
        return player.coloniesCount || 0;
    }
    if (tagName === SpecialTags_1.SpecialTags.INFLUENCE) {
        return player.influence || 0;
    }
    if (tagName === SpecialTags_1.SpecialTags.CITY_COUNT) {
        return player.citiesCount || 0;
    }
    if (tagName === SpecialTags_1.SpecialTags.NONE) {
        return player.noTagsCount || 0;
    }
    return (_b = (_a = player.tags.find((tag) => tag.tag === tagName)) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0;
};
exports.default = vue_1.default.extend({
    name: 'PlayerTags',
    props: {
        playerView: {
            type: Object,
        },
        player: {
            type: Object,
        },
        hideZeroTags: {
            type: Boolean,
        },
        isTopBar: {
            type: Boolean,
            default: false,
        },
        conciseTagsViewDefaultValue: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const x = ORDER.map((key) => [key, { name: key, discount: 0, points: 0, count: getTagCount(key, this.player), halfPoints: 0 }]);
        const details = Object.fromEntries(x);
        details['all'] = { name: 'all', discount: (_b = (_a = this.player) === null || _a === void 0 ? void 0 : _a.cardDiscount) !== null && _b !== void 0 ? _b : 0, points: 0, count: 0, halfPoints: 0 };
        for (const card of this.player.tableau) {
            for (const discount of (_c = card.discount) !== null && _c !== void 0 ? _c : []) {
                const tag = (_d = discount.tag) !== null && _d !== void 0 ? _d : 'all';
                details[tag].discount += discount.amount;
            }
            if (card.name === CardName_1.CardName.CULTIVATION_OF_VENUS || card.name === CardName_1.CardName.VENERA_BASE) {
                details[Tag_1.Tag.VENUS].halfPoints++;
            }
            else {
                const vps = (_e = (0, ClientCardManifest_1.getCard)(card.name)) === null || _e === void 0 ? void 0 : _e.victoryPoints;
                if (vps !== undefined && typeof (vps) !== 'number' && vps !== 'special' && vps.tag !== undefined) {
                    details[vps.tag].points += (((_f = vps.each) !== null && _f !== void 0 ? _f : 1) / ((_g = vps.per) !== null && _g !== void 0 ? _g : 1));
                }
            }
        }
        if (((_h = this.playerView.game.turmoil) === null || _h === void 0 ? void 0 : _h.ruling) === PartyName_1.PartyName.UNITY &&
            ((_j = this.playerView.game.turmoil.politicalAgendas) === null || _j === void 0 ? void 0 : _j.unity.policyId) === 'up04') {
            details[Tag_1.Tag.SPACE].discount += 2;
        }
        const tagsInOrder = [];
        for (const tag of ORDER) {
            const entry = details[tag];
            tagsInOrder.push(entry);
        }
        return {
            all: details['all'],
            tagsInOrder,
        };
    },
    components: {
        'tag-count': TagCount_vue_1.default,
        PlayerTagDiscount: PlayerTagDiscount_vue_1.default,
        PointsPerTag: PointsPerTag_vue_1.default,
    },
    computed: {
        isThisPlayer() {
            var _a;
            return this.player.color === ((_a = this.playerView.thisPlayer) === null || _a === void 0 ? void 0 : _a.color);
        },
        cardsInHandCount() {
            var _a;
            return (_a = this.player.cardsInHandNbr) !== null && _a !== void 0 ? _a : 0;
        },
        hideVpCount() {
            return !this.playerView.game.gameOptions.showOtherPlayersVP && !this.isThisPlayer;
        },
        isEscapeVelocityOn() {
            return this.playerView.game.gameOptions.escapeVelocityMode;
        },
        escapeVelocityPenalty() {
            return this.player.victoryPointsBreakdown.escapeVelocity;
        },
        tooltipCss() {
            return 'tooltip tooltip-' + (this.isTopBar ? 'bottom' : 'top');
        },
        tags() {
            var _a, _b;
            const concise = (_b = (_a = (0, vueRoot_1.vueRoot)(this).componentsVisibility) === null || _a === void 0 ? void 0 : _a['tags_concise']) !== null && _b !== void 0 ? _b : this.conciseTagsViewDefaultValue;
            return this.tagsInOrder.filter((entry) => {
                if (!isInGame(entry.name, this.playerView.game)) {
                    return false;
                }
                if (entry.count === 0 && entry.discount === 0) {
                    if (this.hideZeroTags || concise) {
                        return false;
                    }
                }
                return true;
            });
        },
    },
});
