"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MODULE_NAMES = exports.GAME_MODULES = void 0;
exports.GAME_MODULES = [
    'base',
    'corpera',
    'promo',
    'venus',
    'colonies',
    'prelude',
    'prelude2',
    'turmoil',
    'community',
    'ares',
    'moon',
    'pathfinders',
    'ceo',
    'starwars',
];
exports.MODULE_NAMES = {
    'base': 'Base',
    'corpera': 'Corporate Era',
    'promo': 'Promo',
    'venus': 'Venus Next',
    'colonies': 'Colonies',
    'prelude': 'Prelude',
    'prelude2': 'Prelude 2',
    'turmoil': 'Turmoil',
    'community': 'Community',
    'ares': 'Ares',
    'moon': 'The Moon',
    'pathfinders': 'Pathfinders',
    'ceo': 'CEOs',
    'starwars': 'Star Wars',
};
