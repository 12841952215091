"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const MODULE_TO_CSS = {
    'corpera': 'corporate-icon',
    'promo': 'promo-icon',
    'venus': 'venus-icon',
    'colonies': 'colonies-icon',
    'prelude': 'prelude-icon',
    'prelude2': 'prelude2-icon',
    'turmoil': 'turmoil-icon',
    'community': 'community-icon',
    'ares': 'ares-icon',
    'moon': 'moon-icon',
    'pathfinders': 'pathfinders-icon',
    'ceo': 'ceo-icon',
    'starwars': 'starwars-icon',
};
exports.default = vue_1.default.extend({
    name: 'CardExpansion',
    props: {
        expansion: {
            type: String,
            required: true,
        },
        isCorporation: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        classes() {
            const classes = ['card-expansion', 'project-icon'];
            if (this.expansion !== 'base') {
                classes.push(MODULE_TO_CSS[this.expansion]);
            }
            if (this.isCorporation) {
                classes.push('card-corporation-expansion');
            }
            return classes.join(' ');
        },
    },
});
