"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Payment_1 = require("@/common/inputs/Payment");
const PaymentWidgetMixin_1 = require("@/client/mixins/PaymentWidgetMixin");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
exports.default = vue_1.default.extend({
    name: 'SelectPayment',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    computed: {
        thisPlayer: function () {
            return this.playerView.thisPlayer;
        },
    },
    components: {
        AppButton: AppButton_vue_1.default,
    },
    data() {
        return {
            cost: 0,
            heat: 0,
            megaCredits: 0,
            steel: 0,
            titanium: 0,
            plants: 0,
            microbes: 0,
            floaters: 0,
            seeds: 0,
            auroraiData: 0,
            kuiperAsteroids: 0,
            spireScience: 0,
            warning: undefined,
        };
    },
    mounted() {
        vue_1.default.nextTick(() => {
            this.setInitialCost();
            this.megaCredits = this.getMegaCreditsMax();
            this.setDefaultValues();
        });
    },
    methods: Object.assign(Object.assign({}, PaymentWidgetMixin_1.PaymentWidgetMixin.methods), { hasWarning() {
            return this.warning !== undefined;
        },
        setInitialCost() {
            var _a;
            this.cost = (_a = this.playerinput.amount) !== null && _a !== void 0 ? _a : 0;
        },
        setDefaultValue(mcAlreadyCovered, unit) {
            if (!this.canUse(unit)) {
                return 0;
            }
            const availableUnits = this.getAvailableUnits(unit);
            if (availableUnits === 0) {
                return 0;
            }
            const cost = this.cost;
            const targetResourceRate = this.getResourceRate(unit);
            let contributingUnits = Math.ceil(Math.max(cost - this.getAvailableUnits('megaCredits') - mcAlreadyCovered, 0) / targetResourceRate);
            contributingUnits = Math.min(contributingUnits, availableUnits);
            let contributingMCValue = contributingUnits * targetResourceRate;
            const greedy = unit !== 'heat';
            if (greedy === true) {
                while (contributingUnits < availableUnits && contributingMCValue <= cost - targetResourceRate) {
                    contributingUnits++;
                    contributingMCValue += targetResourceRate;
                }
            }
            this.$data[unit] = contributingUnits;
            return contributingMCValue;
        },
        setDefaultValues(reserveMegacredits = false) {
            const cost = this.cost;
            const megaCredits = this.getAvailableUnits('megaCredits');
            let amountCovered = reserveMegacredits ? megaCredits : 0;
            for (const unit of ['seeds', 'auroraiData', 'steel', 'titanium', 'heat', 'spireScience']) {
                amountCovered += this.setDefaultValue(amountCovered, unit);
            }
            if (!reserveMegacredits) {
                this.megaCredits = Math.min(megaCredits, Math.max(cost - amountCovered, 0));
            }
        },
        setMaxMCValue() {
            this.setMaxValue('megaCredits');
            this.setDefaultValues(true);
        },
        canAffordWithMcOnly() {
            return this.thisPlayer.megaCredits >= this.cost;
        },
        canUse(unit) {
            if (unit === 'megaCredits') {
                return true;
            }
            if (unit === 'titanium') {
                if (this.thisPlayer.titanium === 0) {
                    return false;
                }
                return this.playerinput.paymentOptions.titanium || this.playerinput.paymentOptions.lunaTradeFederationTitanium;
            }
            return this.playerinput.paymentOptions[unit] && this.hasUnits(unit);
        },
        saveData() {
            var _a;
            const payment = Object.assign({}, Payment_1.Payment.EMPTY);
            let totalSpent = 0;
            for (const target of Payment_1.PAYMENT_UNITS) {
                payment[target] = (_a = this[target]) !== null && _a !== void 0 ? _a : 0;
                totalSpent += payment[target] * this.getResourceRate(target);
            }
            for (const target of Payment_1.PAYMENT_UNITS) {
                if (payment[target] > this.getAvailableUnits(target)) {
                    this.warning = `You do not have enough ${target}`;
                    return;
                }
            }
            const requiredAmt = this.playerinput.amount || 0;
            if (requiredAmt > 0 && totalSpent < requiredAmt) {
                this.warning = 'Haven\'t spent enough';
                return;
            }
            if (requiredAmt === 0) {
                payment.heat = 0;
                payment.megaCredits = 0;
            }
            if (requiredAmt > 0 && totalSpent > requiredAmt) {
                const diff = totalSpent - requiredAmt;
                for (const target of Payment_1.PAYMENT_UNITS) {
                    if (payment[target] && diff >= this.getResourceRate(target)) {
                        this.warning = `You cannot overspend ${target}`;
                        return;
                    }
                }
            }
            const showAlert = (0, PreferencesManager_1.getPreferences)().show_alerts;
            if (requiredAmt > 0 && totalSpent > requiredAmt && showAlert) {
                const diff = totalSpent - requiredAmt;
                if (!confirm('Warning: You are overpaying by ' + diff + ' M€')) {
                    this.warning = 'Please adjust payment amount';
                    return;
                }
            }
            this.onsave({ type: 'payment', payment: payment });
        } }),
});
