"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRequirementDescriptor_1 = require("@/common/cards/CardRequirementDescriptor");
const RequirementType_1 = require("@/common/cards/RequirementType");
const utils_1 = require("@/common/utils/utils");
const CardParty_vue_1 = require("@/client/components/card/CardParty.vue");
const PartyName_1 = require("@/common/turmoil/PartyName");
exports.default = vue_1.default.extend({
    name: 'CardRequirementComponent',
    props: {
        requirement: {
            type: Object,
            required: true,
        },
        leftMargin: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    components: {
        CardParty: CardParty_vue_1.default,
    },
    computed: {
        type() {
            return (0, CardRequirementDescriptor_1.requirementType)(this.requirement);
        },
        count() {
            var _a;
            return (_a = this.requirement.count) !== null && _a !== void 0 ? _a : 0;
        },
        amount() {
            switch (this.type) {
                case RequirementType_1.RequirementType.TEMPERATURE:
                case RequirementType_1.RequirementType.OXYGEN:
                case RequirementType_1.RequirementType.VENUS:
                case RequirementType_1.RequirementType.HABITAT_RATE:
                case RequirementType_1.RequirementType.MINING_RATE:
                case RequirementType_1.RequirementType.LOGISTIC_RATE:
                    return this.count;
            }
            if (this.requirement.max) {
                return this.count;
            }
            if (this.count === 0) {
                return '';
            }
            if (this.count !== 1) {
                return this.count;
            }
            return '';
        },
        suffix() {
            switch (this.type) {
                case RequirementType_1.RequirementType.OXYGEN:
                case RequirementType_1.RequirementType.VENUS:
                    return '%';
                case RequirementType_1.RequirementType.TEMPERATURE:
                    return '°C';
            }
            return '';
        },
        isAny() {
            return this.requirement.all ? 'red-outline' : '';
        },
        componentClasses() {
            const classes = this.componentClassArray;
            if (this.requirement.all) {
                classes.push('red-outline');
            }
            return classes;
        },
        componentClassArray() {
            switch (this.type) {
                case RequirementType_1.RequirementType.OXYGEN:
                    return ['card-global-requirement', 'card-oxygen--req'];
                case RequirementType_1.RequirementType.TEMPERATURE:
                    return ['card-global-requirement', 'card-temperature--req'];
                case RequirementType_1.RequirementType.OCEANS:
                    return ['card-global-requirement', 'card-ocean--req'];
                case RequirementType_1.RequirementType.VENUS:
                    return ['card-global-requirement', 'card-venus--req'];
                case RequirementType_1.RequirementType.TR:
                    return ['card-tile', 'card-tr', 'card-tr--req'];
                case RequirementType_1.RequirementType.RESOURCE_TYPES:
                    return ['card-resource', 'card-resource-wild'];
                case RequirementType_1.RequirementType.GREENERIES:
                    return ['card-tile', 'greenery-tile--M', 'tile--req'];
                case RequirementType_1.RequirementType.CITIES:
                    return ['card-tile', 'city-tile--M', 'tile--req'];
                case RequirementType_1.RequirementType.COLONIES:
                    return ['card-resource-colony', 'card-resource-colony--req'];
                case RequirementType_1.RequirementType.FLOATERS:
                    return ['card-resource-tag--S', 'card-tag-floater'];
                case RequirementType_1.RequirementType.CHAIRMAN:
                    return ['card-chairman--req'];
                case RequirementType_1.RequirementType.PARTY_LEADERS:
                    return ['card-party-leader--req'];
                case RequirementType_1.RequirementType.TAG:
                    return ['card-resource-tag--S', 'card-tag-' + this.requirement.tag];
                case RequirementType_1.RequirementType.HABITAT_RATE:
                    return ['card-colony-rate', 'card-colony-rate--req'];
                case RequirementType_1.RequirementType.MINING_RATE:
                    return ['card-mining-rate', 'card-mining-rate--req'];
                case RequirementType_1.RequirementType.LOGISTIC_RATE:
                    return ['card-logistics-rate', 'card-logistics-rate--req'];
                case RequirementType_1.RequirementType.HABITAT_TILES:
                    return ['card-tile-lunar-habitat--S', 'tile--req'];
                case RequirementType_1.RequirementType.MINING_TILES:
                    return ['card-tile-lunar-mine--S', 'tile--req'];
                case RequirementType_1.RequirementType.ROAD_TILES:
                    return ['card-tile-lunar-road--S', 'tile--req'];
                case RequirementType_1.RequirementType.PRODUCTION:
                case RequirementType_1.RequirementType.REMOVED_PLANTS:
                    break;
            }
            return [];
        },
        party() {
            if (this.type === RequirementType_1.RequirementType.PARTY && this.requirement.party) {
                return this.requirement.party;
            }
            else {
                return PartyName_1.PartyName.GREENS;
            }
        },
        productionClass() {
            if (this.type === RequirementType_1.RequirementType.PRODUCTION) {
                const resource = this.requirement.production;
                return `card-resource card-resource-${resource}`;
            }
            else {
                return '';
            }
        },
        RequirementType() {
            return RequirementType_1.RequirementType;
        },
        isRepeated() {
            switch (this.type) {
                case RequirementType_1.RequirementType.OXYGEN:
                case RequirementType_1.RequirementType.TEMPERATURE:
                case RequirementType_1.RequirementType.VENUS:
                case RequirementType_1.RequirementType.PARTY:
                case RequirementType_1.RequirementType.REMOVED_PLANTS:
                    return false;
            }
            return this.count < 4;
        },
        repeats() {
            if (!this.isRepeated || this.requirement.count === undefined) {
                return [1];
            }
            return (0, utils_1.range)(this.requirement.count);
        },
        nextTo() {
            if (this.requirement.nextTo) {
                return 'nextto-leftside';
            }
            if (this.leftMargin) {
                return 'nextto-rightside';
            }
            return '';
        },
    },
});
