"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
exports.default = vue_1.default.extend({
    name: 'and-options',
    props: {
        playerView: {
            type: Object,
        },
        players: {
            type: Array,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        AppButton: AppButton_vue_1.default,
    },
    data() {
        if (this.playerinput.options === undefined) {
            throw new Error('options must be defined');
        }
        return {
            responded: this.playerinput.options.map(() => undefined),
        };
    },
    methods: {
        playerFactorySaved(idx) {
            return (out) => {
                this.$data.responded[idx] = out;
            };
        },
        canSave() {
            for (const child of this.$children) {
                const canSave = child.canSave;
                if (canSave instanceof Function) {
                    if (canSave() === false) {
                        return false;
                    }
                }
            }
            return true;
        },
        saveData() {
            if (this.canSave() === false) {
                alert('Not all options selected');
                return;
            }
            for (const child of this.$children) {
                if (child.saveData instanceof Function) {
                    child.saveData();
                }
            }
            this.onsave({
                type: 'and',
                responses: this.responded,
            });
        },
    },
});
