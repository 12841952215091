"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requirementType = void 0;
const RequirementType_1 = require("./RequirementType");
function requirementType(descriptor) {
    if (descriptor.tag !== undefined) {
        return RequirementType_1.RequirementType.TAG;
    }
    else if (descriptor.oceans !== undefined) {
        return RequirementType_1.RequirementType.OCEANS;
    }
    else if (descriptor.oxygen !== undefined) {
        return RequirementType_1.RequirementType.OXYGEN;
    }
    else if (descriptor.temperature !== undefined) {
        return RequirementType_1.RequirementType.TEMPERATURE;
    }
    else if (descriptor.venus !== undefined) {
        return RequirementType_1.RequirementType.VENUS;
    }
    else if (descriptor.tr !== undefined) {
        return RequirementType_1.RequirementType.TR;
    }
    else if (descriptor.chairman !== undefined) {
        return RequirementType_1.RequirementType.CHAIRMAN;
    }
    else if (descriptor.resourceTypes !== undefined) {
        return RequirementType_1.RequirementType.RESOURCE_TYPES;
    }
    else if (descriptor.greeneries !== undefined) {
        return RequirementType_1.RequirementType.GREENERIES;
    }
    else if (descriptor.cities !== undefined) {
        return RequirementType_1.RequirementType.CITIES;
    }
    else if (descriptor.colonies !== undefined) {
        return RequirementType_1.RequirementType.COLONIES;
    }
    else if (descriptor.floaters !== undefined) {
        return RequirementType_1.RequirementType.FLOATERS;
    }
    else if (descriptor.partyLeader !== undefined) {
        return RequirementType_1.RequirementType.PARTY_LEADERS;
    }
    else if (descriptor.production !== undefined) {
        return RequirementType_1.RequirementType.PRODUCTION;
    }
    else if (descriptor.party !== undefined) {
        return RequirementType_1.RequirementType.PARTY;
    }
    else if (descriptor.plantsRemoved !== undefined) {
        return RequirementType_1.RequirementType.REMOVED_PLANTS;
    }
    else if (descriptor.habitatRate !== undefined) {
        return RequirementType_1.RequirementType.HABITAT_RATE;
    }
    else if (descriptor.miningRate !== undefined) {
        return RequirementType_1.RequirementType.MINING_RATE;
    }
    else if (descriptor.logisticRate !== undefined) {
        return RequirementType_1.RequirementType.LOGISTIC_RATE;
    }
    else if (descriptor.habitatTiles !== undefined) {
        return RequirementType_1.RequirementType.HABITAT_TILES;
    }
    else if (descriptor.miningTiles !== undefined) {
        return RequirementType_1.RequirementType.MINING_TILES;
    }
    else if (descriptor.roadTiles !== undefined) {
        return RequirementType_1.RequirementType.ROAD_TILES;
    }
    else {
        throw new Error('Unknown requirement: ' + JSON.stringify(descriptor));
    }
}
exports.requirementType = requirementType;
