"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const SpaceType_1 = require("@/common/boards/SpaceType");
const TileType_1 = require("@/common/TileType");
const tileTypeToCssClass = {
    [TileType_1.TileType.OCEAN]: 'ocean',
    [TileType_1.TileType.CITY]: 'city',
    [TileType_1.TileType.GREENERY]: 'greenery',
    [TileType_1.TileType.COMMERCIAL_DISTRICT]: 'commercial_district',
    [TileType_1.TileType.ECOLOGICAL_ZONE]: 'ecological_zone',
    [TileType_1.TileType.INDUSTRIAL_CENTER]: 'industrial_center',
    [TileType_1.TileType.LAVA_FLOWS]: 'lava_flows',
    [TileType_1.TileType.MINING_AREA]: 'mining_area',
    [TileType_1.TileType.MINING_RIGHTS]: 'mining_rights',
    [TileType_1.TileType.CAPITAL]: 'capital',
    [TileType_1.TileType.MOHOLE_AREA]: 'mohole_area',
    [TileType_1.TileType.NATURAL_PRESERVE]: 'natural_preserve',
    [TileType_1.TileType.NUCLEAR_ZONE]: 'nuclear_zone',
    [TileType_1.TileType.RESTRICTED_AREA]: 'restricted_area',
    [TileType_1.TileType.DEIMOS_DOWN]: 'deimos_down',
    [TileType_1.TileType.GREAT_DAM]: 'great_dam',
    [TileType_1.TileType.MAGNETIC_FIELD_GENERATORS]: 'magnetic_field_generators',
    [TileType_1.TileType.BIOFERTILIZER_FACILITY]: 'biofertilizer-facility',
    [TileType_1.TileType.METALLIC_ASTEROID]: 'metallic-asteroid',
    [TileType_1.TileType.SOLAR_FARM]: 'solar-farm',
    [TileType_1.TileType.OCEAN_CITY]: 'ocean-city',
    [TileType_1.TileType.OCEAN_FARM]: 'ocean-farm',
    [TileType_1.TileType.OCEAN_SANCTUARY]: 'ocean-sanctuary',
    [TileType_1.TileType.DUST_STORM_MILD]: 'dust-storm-mild',
    [TileType_1.TileType.DUST_STORM_SEVERE]: 'dust-storm-severe',
    [TileType_1.TileType.EROSION_MILD]: 'erosion-mild',
    [TileType_1.TileType.EROSION_SEVERE]: 'erosion-severe',
    [TileType_1.TileType.MINING_STEEL_BONUS]: 'mining-steel',
    [TileType_1.TileType.MINING_TITANIUM_BONUS]: 'mining-titanium',
    [TileType_1.TileType.WETLANDS]: 'wetlands',
    [TileType_1.TileType.RED_CITY]: 'red-city',
    [TileType_1.TileType.MARTIAN_NATURE_WONDERS]: 'martian-nature-wonders',
    [TileType_1.TileType.MOON_ROAD]: 'road',
    [TileType_1.TileType.MOON_HABITAT]: 'habitat',
    [TileType_1.TileType.MOON_MINE]: 'mine',
    [TileType_1.TileType.LUNA_TRADE_STATION]: 'luna-trade-station',
    [TileType_1.TileType.LUNA_MINING_HUB]: 'luna-mining-hub',
    [TileType_1.TileType.LUNA_TRAIN_STATION]: 'luna-train-station',
    [TileType_1.TileType.LUNAR_MINE_URBANIZATION]: 'lunar-mine-urbanization',
    [TileType_1.TileType.CRASHLANDING]: 'crashlanding',
    [TileType_1.TileType.MARS_NOMADS]: '',
};
const tileTypeToCssClassAresOverride = new Map([
    [TileType_1.TileType.COMMERCIAL_DISTRICT, 'commercial-district-ares'],
    [TileType_1.TileType.DEIMOS_DOWN, 'deimos-down-ares'],
    [TileType_1.TileType.ECOLOGICAL_ZONE, 'ecological-zone-ares'],
    [TileType_1.TileType.GREAT_DAM, 'great-dam-ares'],
    [TileType_1.TileType.INDUSTRIAL_CENTER, 'industrial-center-ares'],
    [TileType_1.TileType.LAVA_FLOWS, 'lava-flows-ares'],
    [TileType_1.TileType.CAPITAL, 'capital-ares'],
    [TileType_1.TileType.MOHOLE_AREA, 'mohole-area-ares'],
    [TileType_1.TileType.NATURAL_PRESERVE, 'natural-preserve-ares'],
    [TileType_1.TileType.NUCLEAR_ZONE, 'nuclear-zone-ares'],
    [TileType_1.TileType.RESTRICTED_AREA, 'restricted-area-ares'],
    [TileType_1.TileType.MAGNETIC_FIELD_GENERATORS, 'magnetic-field-generators-ares'],
]);
const descriptions = {
    [TileType_1.TileType.MOHOLE_AREA]: 'Mohole Area',
    [TileType_1.TileType.COMMERCIAL_DISTRICT]: 'Commercial District: 1 VP per adjacent city tile',
    [TileType_1.TileType.ECOLOGICAL_ZONE]: 'Ecological Zone',
    [TileType_1.TileType.INDUSTRIAL_CENTER]: 'Industrial Center',
    [TileType_1.TileType.LAVA_FLOWS]: 'Lava Flows',
    [TileType_1.TileType.CAPITAL]: 'Capital',
    [TileType_1.TileType.MINING_AREA]: 'Mining Area',
    [TileType_1.TileType.MINING_RIGHTS]: 'Mining Rights',
    [TileType_1.TileType.NATURAL_PRESERVE]: 'Natural Preserve',
    [TileType_1.TileType.NUCLEAR_ZONE]: 'Nuclear Zone',
    [TileType_1.TileType.RESTRICTED_AREA]: 'Restricted Area',
    [TileType_1.TileType.GREAT_DAM]: 'Great Dam',
    [TileType_1.TileType.MAGNETIC_FIELD_GENERATORS]: 'Magnetic field generators',
    [TileType_1.TileType.DEIMOS_DOWN]: 'Deimos Down',
    [TileType_1.TileType.CITY]: 'City: 1 VP per adjacent greenery',
    [TileType_1.TileType.GREENERY]: 'Greenery: 1 VP',
    [TileType_1.TileType.OCEAN]: 'Ocean: grants 2M€ when players put tiles next to it',
    [TileType_1.TileType.BIOFERTILIZER_FACILITY]: 'Biofertilizer Facility',
    [TileType_1.TileType.METALLIC_ASTEROID]: 'Metallic Asteroid',
    [TileType_1.TileType.SOLAR_FARM]: 'Solar Farm',
    [TileType_1.TileType.OCEAN_CITY]: 'Ocean City: counts as an ocean and a city.',
    [TileType_1.TileType.OCEAN_FARM]: 'Ocean Farm',
    [TileType_1.TileType.OCEAN_SANCTUARY]: 'Ocean Sanctuary',
    [TileType_1.TileType.DUST_STORM_MILD]: 'Mild Dust Storm: lose 1 production when placing next to it. Pay 8M€ to place over it.',
    [TileType_1.TileType.DUST_STORM_SEVERE]: 'Severe Dust Storm: lose 2 production when placing next to it. Pay 16M€ to place over it.',
    [TileType_1.TileType.EROSION_MILD]: 'Mild Erosion: lose 1 production when placing next to it. Pay 8M€ to place over it.',
    [TileType_1.TileType.EROSION_SEVERE]: 'Severe Erosion: lose 2 production when placing next to it. Pay 16M€ to place over it.',
    [TileType_1.TileType.MINING_STEEL_BONUS]: 'Mining: steel bonus',
    [TileType_1.TileType.MINING_TITANIUM_BONUS]: 'Mining: titanium bonus',
    [TileType_1.TileType.MOON_MINE]: 'Moon Mine: 1 VP per adjacent road',
    [TileType_1.TileType.MOON_HABITAT]: 'Moon Colony: 1 VP per adjacent road',
    [TileType_1.TileType.MOON_ROAD]: 'Moon Road: 1 VP',
    [TileType_1.TileType.LUNA_TRADE_STATION]: 'Luna Trade Station',
    [TileType_1.TileType.LUNA_MINING_HUB]: 'Luna Mining Hub',
    [TileType_1.TileType.LUNA_TRAIN_STATION]: 'Luna Train Station: 2 VP per adjacent road',
    [TileType_1.TileType.LUNAR_MINE_URBANIZATION]: 'Luna Mine Urbanization: counts as both a colony and a mine tile.',
    [TileType_1.TileType.WETLANDS]: 'Wetlands: counts as an ocean and a greenery. Does not count toward 9 oceans.',
    [TileType_1.TileType.RED_CITY]: 'Red City: 1 VP per empty adjacent area. No greeneries may be placed next to it.',
    [TileType_1.TileType.MARTIAN_NATURE_WONDERS]: 'Martian Nature Wonders',
    [TileType_1.TileType.CRASHLANDING]: 'Crashlanding',
    [TileType_1.TileType.MARS_NOMADS]: '',
};
exports.default = vue_1.default.extend({
    name: 'board-space-tile',
    props: {
        space: {
            type: Object,
        },
        aresExtension: {
            type: Boolean,
        },
        tileView: {
            type: String,
            default: 'show',
        },
        restricted: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        tileType() {
            return this.space.tileType;
        },
        spaceType() {
            return this.space.spaceType;
        },
        highlight() {
            return this.space.highlight;
        },
        description() {
            if (this.tileType === undefined)
                return '';
            if (this.tileType === TileType_1.TileType.CITY && this.spaceType === SpaceType_1.SpaceType.COLONY)
                return 'City in space.';
            return descriptions[this.tileType];
        },
        klass() {
            let css = 'board-space';
            if (this.tileType !== undefined) {
                let cssClass = tileTypeToCssClass[this.tileType];
                if (this.aresExtension && tileTypeToCssClassAresOverride.has(this.tileType)) {
                    cssClass = tileTypeToCssClassAresOverride.get(this.tileType);
                }
                if (this.tileType === TileType_1.TileType.CRASHLANDING && this.space.rotated === true) {
                    cssClass += '-rotated';
                }
                css += ' board-space-tile--' + cssClass;
            }
            else {
                if (this.spaceType === SpaceType_1.SpaceType.OCEAN) {
                    css += ' board-space-type-ocean';
                }
                else if (this.spaceType === SpaceType_1.SpaceType.COVE) {
                    if (this.highlight !== 'volcanic') {
                        css += ' board-space-type-cove';
                    }
                    else {
                        css += ' board-space-type-volcanic-cove';
                    }
                }
                else if (!this.restricted) {
                    css += ` board-space-type-land`;
                    if (this.highlight) {
                        css += ` board-space-type-land-${this.highlight}`;
                    }
                }
            }
            if (this.tileView !== 'show') {
                css += ' board-hidden-tile';
            }
            return css;
        },
    },
});
